import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import { Observable, of } from 'rxjs';
import { ErrorMessageService } from '@libs/common-ui/services/error-message/error-message.service';
import { TranslateService } from '@ngx-translate/core';
import {
  TechnicianSimpleProfileModel,
  TechnicianSimpleProfilePage
} from '@libs/common-ui/technician-paginated-selector/technician-simple-profile.model';

@Injectable({
  providedIn: 'root'
})
export class TechnicianLoaderService {

  constructor(private store: Store,
              private httpClient: HttpClient,
              private errorMessageService: ErrorMessageService,
              private translateService: TranslateService) {}

  getTechnicianListPaged(urlRel: string, term: string, pageNumber: number, pageSize: number): Observable<TechnicianSimpleProfileModel[]> {
    return this.store.pipe(getFilteredApiRoot).
      pipe(
        take(1),
      switchMap((apiRoot) => {
        let url = getUrl(apiRoot, urlRel || ApiRootLinkRel.GetTechnicians);
        url = url.replace('searchTerm=', `searchTerm=${term}`) + `&page=${pageNumber}&size=${pageSize}`;
        return this.httpClient.get<TechnicianSimpleProfilePage>(url);
      }),
      map((page) => page.content),
      catchError(response => {
        this.errorMessageService.handleErrorResponseWithCustomMessage(response, this.translateService.instant('SYSTEM.ERROR.LOAD_LIST'));
        return of([]);
      })
    )
  }
}
